import ApiService from "../../service/ApiService";
import {Actions, Mutations} from "../enums/StoreEnums";
import {Module, Action, Mutation, VuexModule} from "vuex-module-decorators";

@Module
export default class DiscussionModule extends VuexModule {
    @Action
    [Actions.SEND_DISCUSSION](payload: object) {
        return ApiService.post("discussions/new_discussion", payload)
            .then((data) => {
                this.context.commit(Mutations.SET_ERROR, {});
                return data;
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                return response
            });
    }
    @Action
    [Actions.UPLOAD_FILE_DISCUSSION](payload: object) {
        return ApiService.postFormData("message_files/upload", payload, {
            headers: {
                "Content-Type": "multipart/form-data",
            }
        })
            .then((data) => {
                this.context.commit(Mutations.SET_ERROR, {});
                return data
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
            });
    }
    @Action
    [Actions.GET_DISCUSSION_LIST](objectData: any) {
        const params = {} as object;

        if (objectData.site) {
            // @ts-ignore
            params['site'] = objectData.site
        }
        if (objectData.type) {
            // @ts-ignore
            params['type'] = objectData.type
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }
        return ApiService.get("discussion/list" + queryString, false)
            .then((data) => {
                this.context.commit(Mutations.SET_ERROR, {});
                return data
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                return response
            });
    }

    @Action
    [Actions.GET_MESSAGE_BY_DISCUSSION_ID](objectData: any) {
        const params = {} as object;

        if (objectData.discussion) {
            // @ts-ignore
            params['discussion'] = objectData.discussion
        }
        if (!objectData.application_master) {
            // @ts-ignore
            params['discussion.user'] = objectData.user
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }

        return ApiService.get("messages" + queryString, objectData.customHeader)
            .then((data) => {
                this.context.commit(Mutations.SET_ERROR, {});
                return data
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                return response
            });
    }
    @Action
    [Actions.SEND_MESSAGE](data: any) {
        return ApiService.post("messages/new_message", data)
            .then((data) => {
                this.context.commit(Mutations.SET_ERROR, {});
                return data
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                return response
            });
    }
    @Action
    [Actions.READ_MESSAGE](data: any) {
        return ApiService.put("messages/" + data.id, data.payload)
            .then((data) => {
                this.context.commit(Mutations.SET_ERROR, {});
                return data
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                return response
            });
    }
    @Action
    [Actions.GET_FORUM](objectData: any) {
        const params = {} as object;

        if (objectData.site) {
            // @ts-ignore
            params['site'] = objectData.site
        }
        if (objectData.type) {
            // @ts-ignore
            params['type'] = objectData.type
        }
        if (objectData.user) {
            // @ts-ignore
            params['discussion.user'] = objectData.user
        }

        // @ts-ignore
        let queryString = Object.keys(params).map(key => key + '=' + params[key]).join('&');
        if (queryString) {
            queryString = '?' + queryString;
        }

        return ApiService.get("discussions" + queryString, false)
            .then((data) => {
                this.context.commit(Mutations.SET_ERROR, {});
                return data
            })
            .catch(({response}) => {
                this.context.commit(Mutations.SET_ERROR, response.data.errors);
                return response
            });
    }
}
