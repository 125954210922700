

import {useStore} from "vuex";
import {Actions} from "@/store/enums/StoreEnums";
import {ref} from "vue";
import router from "@/router";
import Swal from "sweetalert2"
import SiteService from "@/service/siteService";

export default {
  data() {
    return {}
  },
  setup() {
    const store = useStore();
    let email = ref(null);
    let password = ref(null);

    const onSubmitLogin = async function () {
      await store.dispatch(Actions.LOGIN, {
        email: email.value,
        password: password.value
      });
      // const [errorName] = Object.keys(store.getters.getErrors);
      // const error = store.getters.getErrors[errorName];
      const error = store.getters.getErrors
      console.log('error', error)

      if (!error.length) {
        console.log('error', error)
        SiteService.destroyNameSite()
        SiteService.destroyTypeSite()
        SiteService.destroyIdSite()
        const path = localStorage.getItem('path')
        if (path) {
          store.commit('setForcePath', true)
          localStorage.removeItem('path')
          // @ts-ignore
          router.push(path)
        } else {
          router.push({name: "dashboard"});
        }


        // Swal.fire({
        //   text: "You have successfully logged in!",
        //   icon: "success",
        //   buttonsStyling: false,
        //   confirmButtonText: "Ok, got it!",
        //   customClass: {
        //     confirmButton: "btn fw-semobold btn-light-primary",
        //   },
        // }).then(function () {
        //   // Go to page after successfully login
        //   router.push({name: "dashboard"});
        //   // });
        // })
      } else {
        Swal.fire({
          text: error,
          icon: "error",
          buttonsStyling: false,
          confirmButtonText: "Try again!",
          customClass: {
            confirmButton: "btn fw-semobold btn-light-danger",
          },
        });
      }

    };
    return {
      store,
      onSubmitLogin,
      email,
      password
    }
  },
  methods: {
    resetPassword() {
      router.push({name: 'ui-ax-reset-password'})

    }
  }

}
