import { createStore } from "vuex";
import { config } from "vuex-module-decorators";
import AuthModule from "./modules/AuthModule";
import DiscussionModule from "./modules/DiscussionModule";
import SiteModule from "@/store/modules/SiteModule";
import ToolsModule from "@/store/modules/ToolsModule";
import TranslationService from "@/service/TranslationService";
// import ThemeModeModule from "./modules/ThemeModeModule";

config.rawError = true;
let language: string | null ='fr';
if (TranslationService.getLang()){
  language = TranslationService.getLang()
}
const store = createStore({

  modules: {
    AuthModule,
    SiteModule,
    ToolsModule,
    DiscussionModule
    // ThemeModeModule,
  },
  state: {
    topic: 'Chat',
    currentLanguage: language,
    initStatus: false,
    selectedNode: null,
    forcePath: false,
    topbarKey: 0,
    initSiteRte: false,
    listSites: null,
  },
  mutations: {
    setInitStatus(state, value) {
      state.initStatus = value;
    },
    setSelectedNode(state, value) {
      state.selectedNode = value;
    },
    setForcePath(state, value) {
      state.forcePath = value;
    },
    incrementToBarKey(state) {
      state.topbarKey = Math.floor(Math.random() * 50);
    },
    setRte(state, value){
      state.rte = value
    },
    setSiteRte(state, value){
      state.siteRte = value
    },
    setIsInitSiteRte(state, value){
      state.selectedNode = value
    },
    setListSites(state, value) {
      state.listSites = value
    }
  },
  getters: {
    getTopBarKey(state) {
      return state.topbarKey;
    },
    getForcePath(state) {
      return state.forcePath;
    },
    getInitStatus(state) {
      return state.initStatus;
    },
    getSetSelectedNode(state) {
      return state.selectedNode;
    },
    isRte(state) {
      return state?.selectedNode?.customer_id == 355;
    },
    isSite(state) {
      return state?.selectedNode?.type === 'site';
    },
    isSiteRte(state, getters) {
      return getters.isSite && getters.isRte;
    },
    isInitSiteRte(state, getters) {
      return getters.isSite && getters.isRte && state?.selectedNode?.init_status;
    },
    getYearCustomer(state){
      return state?.selectedNode?.custom_year
    },
    getListSites(state) {
      return state.listSites;
    },
  },
});

export default store;
