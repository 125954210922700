enum fr {
    textManagemet = 'Gestion des textes',
    userManagement = 'Gestion des utilisateurs',
    AddRegulatoryText = 'Ajouter texte réglementaire',
    editRegulatoryText = 'Éditer texte réglementaire',
    search = 'Rechercher',
    theme = 'Thème',
    domain = 'Domaine',
    subDomain = 'Sous-Domaine',
    text = 'Texte',
    select = 'Sélectionner',
    yourText = 'Votre texte',
    result = 'Résultat',
    dateOfText = 'Date du texte',
    updatedAt = 'Modifié le',
    updatedBy = 'Modifié par',
    priority = 'Priorité',
    update = 'Mise à jour',
    NoDataToDisplay = 'Pas de données à afficher',
    LoadingDataPleaseWait = 'Chargement des données. Veuillez patienter',
    fieldApplication = 'Champ d\'application',
    title = 'Titre',
    status = 'Statut',
    defaultApplicability = 'Applicabilité par défaut',
    country = 'Pays',
    confidential = 'Confidentiel',
    comment = 'Commentaire',
    keyDates = 'Dates-Clé',
    dateOfLastModification = 'Date de derniére modification',
    textModifier = 'Texte Modificateur',
    siteAssignment = 'Affectation du site',
    sitesAssociated = 'Sites associés',
    SitesNotAssociated = 'Sites non associé',
    reference = 'Référence',
    applicability = 'Applicabilité',
    wording = 'Libellé',
    titleOfCompliance = 'Titre de l\'exigence',
    titleOfText = 'Titre de texte',
    addASection = 'Ajouter une section',
    editSection = 'Éditer une section',
    edit = 'Éditer',
    add = 'Ajouter',
    delete = 'Supprimer',
    link = 'Lien',
    synthesis = 'Synthèse',
    addACompliance = 'Ajout d\'une exigence',
    editCompliance = 'Éditer une exigence',
    order = 'Ordre',
    AreYouSureYouWantToDelete = 'Êtes-vous sûr de vouloir supprimer?',
    addUser = 'Ajouter un utilisateur',
    editUser = 'Éditer un utilisateur',
    lastname = 'Nom',
    firstname = 'Prénom',
    email = 'Email',
    department = 'Département',
    details = 'Détails',
    fixedTel = 'Téléphone fixe',
    or = 'ou',
    deleted = 'Supprimé',
    cancel = 'Annuler',
    next = 'Suivant',
    save = 'Enregistrer',
    parameters = 'Paramétres',
    language = 'Langue',
    validityEndDate = 'Date de fin de validité',
    creationDate = 'Date de création',
    createdBy = 'Créé par',
    modificationDate = 'Date de modification',
    roleBySite = 'Rôle par site',
    statistics = 'Statistiques',
    lastConnection = 'Derniére connexion',
    firstConnection = 'Premiére  connection',
    numberOfConnection = 'Nombre de connexion',
    averageTimePerConnection = 'Temps moyen par connexion',
    connected = 'Connected',
    customerManagement = 'Gestion des clients',
    CopyTheBase = 'Copier la base',
    addACustomer = 'Ajouter un client',
    siteManagement = 'Gestion des sites',
    addASite = 'Ajouter un site',
    developAll = 'Développer tout',
    cutThroughAll = 'Réduire tout',
    parentSite = 'Site parent',
    informationsOnTheSite = 'Informations du site',
    address = 'Adresse',
    additionalAddress = 'Adresse Complémentaire',
    zipCode = 'Code Postal',
    city = 'Ville',
    phoneNumber = 'Numéro de téléphone',
    group = 'Groupe',
    LambertCoordinates = 'Coordonnées Lambert',
    listOfTexts = 'Liste de textes',
    modify = 'Modifier',
    managingEvaluations = 'Gestion des Évaluations',
    progressRate = 'Taux d\'avancement',
    complianceRate = 'Taux de conformité',
    transversalAssessment = 'Évaluation transverse',
    textEvaluation = 'Évaluation par texte',
    textOfCompliance = 'Texte de l\'exigence',
    evaluation = 'Évaluation',
    evaluations = 'Évaluations',
    valuationDate = 'Date d\'évaluation',
    historic = 'Historique',
    customerAnnotation = 'Annotation client',
    novalliaAnnotation = 'Annotation novallia',
    complianceAnnotation = 'Annotation Exigence',
    ValuedBy = 'Evalué par',
    SynthPlug = 'Fiche de synthése',
    AppendicesAndIllustrationsFile = 'Fichier Annexes et illustrations',
    supportingDocuments = 'Documents annexes',
    titleRequired = 'Titre est requis',
    statusRequired = 'status est requis',
    documentTypeRequired = 'Type document est requis',
    subDomainRequired = 'Sous domaine est requis',
    firstNameRequired = 'Le prénom est requis',
    lastNameRequired = 'Le nom est requis',
    emailRequired = 'Email est requis',
    departmentRequired = 'Département est requis',
    positionRequired = 'Position est requis',
    fixedTelRequired = 'Télephone fixe est requis',
    mobileRequired = 'Mobile est requis',
    addressRequired = 'Adresse est requis',
    codeRequired = 'Code est requis',
    referenceRequired = 'Référence est requise',
    orderRequired = 'Ordre est requis',
    priorityRequired = 'Priorité est requis',
    startDateRequired = 'Date de début est requis',
    endDateRequired = 'Date de fin est requis',
    revisedEndDateRequired = 'Date de fin révisée est requis',
    responsibleAPRequired = "Chargé du plan d'action est requis",
    statusEvaluationRequired = "Le status d'évaluation est requis",
    urlRequired = "L'URL est requise",
    commentRequired = "Le commentaire est obligatoire",
    userHasSuccessfullyModified = 'L\'utilisateur a été modifié avec succès',
    userHasNotAnserToAllQuiz = 'Veuillez répondre à toutes les questions manquantes avant de soumettre le formulaire.',
    userSaveQuiz = 'Le questionnaire a été bien pris en compte. La grille d\'audit sera mise à jour en conséquence',
    userShouldAnserToQuiz = 'Attention, vous devrez répondre (par oui ou par non) à l\'ensemble des questions. Cependant vous pourrez rappeler le questionnaire et le corriger si nécessaire.',
    userHasSuccessfullyAdded = 'L\'utilisateur a été ajoutée avec succès',
    userHasSuccessfullyDeleted = 'L\'utilisateur a été supprimé avec succès',
    rowReordered = 'Rangées réorganisées',
    documentHasSuccessfullyAdded = 'Le texte a été ajoutée avec succès',
    documentHasSuccessfullyModified = 'Le texte a été modifié avec succès',
    complianceHasSuccessfullyAdded = 'L\'exigence a été ajoutée avec succès',
    ComplianceHasSuccessfullyModified = 'L\'exigence a été modifié avec succès',
    ComplianceHasSuccessfullyDeleted = 'L\'exigence a été supprimé avec succès',
    actionPlanHasSuccessfullyDeleted = 'Le plan d\'action a été supprimé avec succès',
    taskAPHasSuccessfullyDeleted = 'Le tâche du plan d\'action a été supprimé avec succès',
    commentAPHasSuccessfullyDeleted = 'Le commentaire du plan d\'action a été supprimé avec succès',
    sectionHasSuccessfullyAdded = 'La section a été ajoutée avec succès',
    SectionHasSuccessfullyModified = 'La section a été modifié avec succès',
    SectionHasSuccessfullyDeleted = 'La section a été supprimé avec succès',
    fileHasSuccessfullyDeleted = 'Le fichier a été supprimé avec succès',
    customerHasSuccessfullyAdded = 'Le client a été ajoutée avec succès',
    customerHasSuccessfullyModified = 'Le client a été modifié avec succès',
    siteHasSuccessfullyAdded = 'Le site a été ajoutée avec succès',
    siteHasSuccessfullyModified = 'Le site a été modifié avec succès',
    evaluationHasSuccessfullyAdded = 'L\'évaluation a été ajoutée avec succès',
    evaluationHasSuccessfullyModified = 'L\'évaluation a été modifié avec succès',
    actionPlanHasSuccessfullyAdded = 'Plan d\'action a été ajoutée avec succès',
    siteAccessDeniedMsg = 'Vous n\'avez pas pas accès à ce plan d\'action. Veuillez vérifier vos identifiants',
    paAccessDeniedMsg = 'Vous n\'avez pas accès à ce plan d\'action',
    paNotFoundMsg = 'Le plan d\'action demandé est introuvable',
    actionPlanHasSuccessfullyModified = 'Plan d\'action a été modifié avec succès',
    exportWasSuccessfullySent = 'Le fichier demandé vous sera envoyé par mail',
    importWasSuccessfullySent = 'Document envoyé avec succès',
    importFileWasSuccessfullySent = 'Fichier envoyé avec succès',
    taskHasSuccessfullyAdded = 'Tâche a été ajoutée avec succès',
    taskHasSuccessfullyModified = 'Tâche a été modifié avec succès',
    commentHasSuccessfullyAdded = 'Commentaire a été ajoutée avec succès',
    commentHasSuccessfullyModfied = 'Commentaire a été modifié avec succès',
    fileSuccessfullyUploaded = 'Fichier téléversé avec succès',
    password = 'Mot de passe',
    confirmPassword = 'Confirmer le mot de passe',
    passwordDidNotMatch = 'Les mots de passe ne correspondent pas',
    validatePassword = 'Le mot de passe doit comprendre un minimum de 8 caractères incluant des majuscules, des minuscules, des chiffres et des caractères spéciaux',
    resetPassword = 'Réinitialiser le mot de passe',
    customer = 'Client',
    invalidMail = 'Adresse email non valide',
    emailUsed = 'Email: Cette valeur est déjà utilisée',
    accessRights = 'Droits d\'accès',
    userRole = 'Rôle de l\'utilisateur',
    activate = 'Activer',
    searchByName = 'Rechercher par nom',
    searchByTheme = 'Rechercher par Théme',
    searchByDomain = 'Rechercher par domaine',
    searchBysubDomain = 'Rechercher par sous domaine',
    requirementEvaluated = ' exigence à évaluer',
    requirementsEvaluated = ' exigences à évaluer',
    requirementReEvaluated = ' exigence à réévaluer',
    requirementsReEvaluated = ' exigences à réévaluer',
    onlineAssistance = 'Assistance en ligne',
    selectedDocuments = 'document(s) sélectionné(s)',
    confirm = 'Valider',
    importSites = 'Importer des sites',
    import = 'Importer',
    importDocument = 'Importer de textes',
    importCompliance = 'Import de conformité',
    importSection = 'Import de sections',
    importAP = 'Import de plan d\'action',
    assignDocuments = 'Affectation des documents',
    importSiteDocument = 'Un import de conformité pour ce site est en cours. Merci pour votre patience\n',
    complianceFile = "Fichier de conformité (xlsx)",
    send = "Envoyer",
    copyBase = "Copier base",
    documentFile = "Fichier de textes (xlsx)",
    sectionsFile = "Fichier de sections (xlsx)",
    actionPlanFile = "Fichier de plan d'action (xlsx)",
    personalisedBase = "Base personnalisée",
    actionPlan = "Plans d’action",
    attachActionPlan = "Attacher un plan d'action",
    addActionPlan = "Ajout d'un PA",
    apPilot = "Pilote du PA",
    responsibleAP = "Chargé du PA",
    typeProgresion = "Type d'avancement",
    rateProgress = "Taux d'avancement",
    startDate = "Date de début",
    endDate = "Date de fin",
    revisedEndDate = "Date de fin révisée",
    fixedReminder = "Relance ponctuelle",
    nbDaysBeforeAPDeadline = "Nombre de jours avant l’échéance du PA",
    periodicRelaunch = "Relance périodique",
    periodicReminderTime = "Délai de relance périodique",
    divers = "Divers",
    tasks = "Tâches",
    archivingVisitReports = "Archivage des rapports de visite",
    compliance = "Exigence",
    addingTasks = "Ajout d’une tâche",
    addingComments = "Ajout d'un commentaire",
    responsibleTasks = "Chargé de la Tâche",
    risksopportunities = "Risques et opportunités",
    process = "Processus",
    measureEffectiveness = "Mesure de l'efficacité",
    verificationType = "Type de vérification",
    responsibleVerification = "Chargé de vérification",
    verificationDate = "Date de vérification",
    resources = "Ressources",
    financialRsources = "Ressources financières",
    operationalResource = "Ressources opérationnelles",
    technologicalResources = "Ressources technologiques",
    humanResources = "Ressources humaines",
    thematic = "Thématique",
    entitled = "Intitulé",
    compliances = "Exigences",
    comments = 'Commentaires',
    importSectionCompliance = 'Un import de découpages est actuellement en cours pour ce texte. Merci pour votre patience\n',
    importActionPlan = 'Un import de plan d\'action est en cours. Merci pour votre patience',
    hideInAP = 'Désactiver dans le plan d\'action',
    listApplicableTexts = 'Liste des textes applicables',
    reportsActionPlan = 'Rapports sur les plans d\'action',
    report = 'Rapport',
    listActionPlanTasks = 'Liste des plans d\'action et tâches',
    listActionPlanCompliance = 'Liste des plans d\'action et exigences\n',
    listNonConformitiesWithoutCompletedAP = 'Liste des non-conformités sans plan d\'actions complétés',
    expiredAP = 'Plans d\'actions échus',
    listEvaluations= 'Liste des évaluations',
    statisticsEvaluations= 'Statistiques des évaluations',
    reportOnComplianceStatus = 'Rapports sur l\'état de conformité',
    nbCompliances = 'Nombre d\'exigences',
    indicators = 'Indicateurs',
    indicatorsRte = 'Indicateurs RTE',
    attachedActionPlan = 'Plan d\'action attaché',
    listOfRequirementReassess = 'Liste des exigences a réévaluer',
    name = 'Nom',
    transversalEvaluation = 'Evaluations transversales',
    successfullyUpdated = 'Mise à jour effectuée avec succès',
    quiz= 'Questionnaire',
    seeAllMessages = 'Voir tous les messages',
    yourMessage = 'Votre message',
    titleOfDiscussion = 'Titre de la discussion',
    SubjectOfDiscussion = 'Sujet de la discussion',
    lastMessage = 'Dernier message',
    discussionLaunchedOn = 'Discussion lancée le',
    dateOfLastMessage = 'Date du dernier message',
    numberOfMessage = 'Nombre des messages',
    StatusReadUnread = 'Statut (Lu / non lu)',
    importFiles = 'Importer des fichiers',
    year = 'Année',
    localTextRte = 'texte local (RTE)',
    currentTasks = 'Tâches en cours',
    user = 'Utilisateur',
    DateOfLastQuestionnaireModification = 'Dernière modification effectuée le',
    by = 'par',
}


export {fr};
