import {createRouter, createWebHashHistory} from 'vue-router';
import store from "@/store";
import {Actions, Mutations} from "@/store/enums/StoreEnums";
import JwtService from "@/service/JwtService";
import siteService from "@/service/siteService";
import UserService from "@/service/UserService";
import {computed} from "vue";
import userService from "@/service/UserService";
import {useToast} from "primevue/usetoast";
// import store from "@/store/index.ts";
// import {Mutations, Actions} from "@/store/enums/StoreEnums.ts";
// import JwtService from "@/service/JwtService.ts";
const routes= [
	{
		path: '/',
		name: 'dashboard',
		meta: {
			pageTitle: "Dashboard",
		},
		component: () => import('./components/Dashboard.vue')
	},
	{
		path: '/dashboards/generic',
		name: 'Tableau de bord',
		component: () => import('./components/Dashboard.vue')
	},
	{
		path: '/dashboards/dashboard_banking',
		name: 'dashboard_banking',
		component: () => import('./components/DashboardBanking.vue')
	},

	{
		path: '/formlayout',
		name: 'test',
		component: () => import('./components/FormLayoutDemo.vue')
	},
	{
		path: '/input',
		name: 'input',
		component: () => import('./components/InputDemo.vue')
	},

	{
		path: '/invalidstate',
		name: 'invalidstate',
		component: () => import('./components/InvalidStateDemo.vue')
	},
	{
		path: '/button',
		name: 'button',
		component: () => import('./components/ButtonDemo.vue')
	},
	{
		path: '/table',
		name: 'table',
		component: () => import('./components/TableDemo.vue')
	},
	{
		path: '/list',
		name: 'list',
		component: () => import('./components/ListDemo.vue')
	},
	{
		path: '/tree',
		name: 'tree',
		component: () => import('./components/TreeDemo.vue')
	},
	{
		path: '/panel',
		name: 'panel',
		component: () => import('./components/PanelsDemo.vue')
	},
	{
		path: '/overlay',
		name: 'overlay',
		component: () => import('./components/OverlaysDemo.vue')
	},
	{
		path: '/media',
		name: 'media',
		component: () => import('./components/MediaDemo.vue')
	},
	{
		path: '/menus',
		component: () => import('./components/MenusDemo.vue'),
		children: [{
			path: '',
			component: () => import('./components/menu/PersonalDemo.vue')
		},
			{
				path: '/menus/seat',
				component: () => import('./components/menu/SeatDemo.vue')
			},
			{
				path: '/menus/payment',
				component: () => import('./components/menu/PaymentDemo.vue')
			},
			{
				path: '/menus/confirmation',
				component: () => import('./components/menu/ConfirmationDemo.vue')
			}]
	},
	{
		path: '/messages',
		name: 'messages',
		component: () => import('./components/MessagesDemo.vue')
	},
	{
		path: '/file',
		name: 'file',
		component: () => import('./components/FileDemo.vue')
	},
	{
		path: '/chart',
		name: 'chart',
		component: () => import('./components/ChartsDemo.vue')
	},
	{
		path: '/misc',
		name: 'misc',
		component: () => import('./components/MiscDemo.vue')
	},
	{
		path: '/icons',
		name: 'icons',
		component: () => import('./utilities/Icons.vue')
	},
	{
		path: '/widgets',
		name: 'widgets',
		component: () => import('./utilities/Widgets.vue')
	},
	{
		path: '/grid',
		name: 'grid',
		component: () => import('./utilities/GridDemo.vue')
	},
	{
		path: '/spacing',
		name: 'spacing',
		component: () => import('./utilities/SpacingDemo.vue')
	},
	{
		path: '/elevation',
		name: 'elevation',
		component: () => import('./utilities/ElevationDemo.vue')
	},
	{
		path: '/typography',
		name: 'typography',
		component: () => import('./utilities/Typography.vue')
	},
	{
		path: '/display',
		name: 'display',
		component: () => import('./utilities/DisplayDemo.vue')
	},
	{
		path: '/flexbox',
		name: 'flexbox',
		component: () => import('./utilities/FlexBoxDemo.vue')
	},
	{
		path: '/text',
		name: 'text',
		component: () => import('./utilities/TextDemo.vue')
	},
	{
		path: '/empty',
		name: 'empty',
		component: () => import('./components/EmptyPage.vue')
	},
	{
		path: '/crud',
		name: 'crud',
		component: () => import('./pages/CrudDemo.vue')
	},
	{
		path: '/calendar',
		name: 'calendar',
		component: () => import('./pages/CalendarDemo.vue')
	},
	{
        path: '/timeline',
        name: 'timeline',
        component: () => import('./pages/TimelineDemo.vue')
    },
	{
		path: '/invoice',
		name: 'invoice',
		component: () => import('./pages/Invoice.vue')
	},
	{
		path: '/help',
		name: 'help',
		component: () => import('./pages/Help.vue')
	},
	{
		path: '/documentation',
		name: 'documentation',
		component: () => import('./components/Documentation.vue')
	},
	{
        path: '/login',
        name: 'login',
        component: () => import('./pages/Login.vue'),
		meta: {
			pageTitle: "Login",
			middleware: "auth",
		},
    },
	{
		path: '/ui-ax/rest-password',
		name: 'ui-ax-reset-password',
		component: () => import('./pages/ResetPassword.vue'),
		meta: {
			pageTitle: "Reset Password",
			middleware: "auth",
		},
	},

	{
		path: '/change-password/:token',
		name: 'change-password',
		meta: {
			pageTitle: "Change-password",
			middleware: "auth",
		},
		component: () => import('./pages/ChangePassword.vue')
	},
    {
        path: '/error',
        name: 'error',
        component: () => import('./pages/Error.vue')
    },
    {
        path: '/notfound',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/access',
        name: 'access',
        component: () => import('./pages/Access.vue')
	},
	{
		path: '/wizard',
		name: 'wizard',
		component: () => import('./pages/Wizard.vue'),
    },
	{
        path: '/blocks',
        name: 'blocks',
        component: () => import('./components/BlocksDemo.vue')
    },{
        path: '/ui-ax/documents',
        name: 'ui-ax-documents',
		meta: {
			pageTitle: "Documents",
		},
        component: () => import('./pages/ui-ax/Document.vue')
    },

	{
		path: '/admin-text',
		name: 'admin-text-general',
		meta: {
			pageTitle: "Admin-texte",
		},
		component: () => import('./pages/Admin/AdminText/AdminTextGeneral.vue')
	},
	{
		path: '/admin/edit-text/:id',
		name: 'edit-text-general',
		meta: {
			pageTitle: "Admin-edit-texte",
		},
		component: () => import('./pages/Admin/AdminText/EditTextGeneral.vue')
	},

	{
		path: '/ui-ax/admin-customer',
		name: 'ui-ax-Admin-customer',
		meta: {
			pageTitle: "Admin-customer",
		},
		component: () => import('./pages/ui-ax/AdminSite/AdminCustomer.vue')
	},
	{
		path: '/ui-ax/admin-site-master/:idCustomer',
		name: 'ui-ax-Admin-site-master',
		meta: {
			pageTitle: "Admin-site",
		},
		component: () => import('./pages/ui-ax/AdminSite/AdminSiteMaster.vue')
	},
	{
		path: '/ui-ax/admin-site',
		name: 'ui-ax-Admin-site',
		meta: {
			pageTitle: "Admin-site",
		},
		component: () => import('./pages/ui-ax/AdminSite/AdminSite.vue')
	},
	{
		path: '/ui-ax/admin-user',
		name: 'ui-ax-Admin-user',
		meta: {
			pageTitle: "Admin-user",
		},
		component: () => import('./pages/ui-ax/AdminUser.vue')
	},
	{
		path: '/admin/profile',
		name: 'profile',
		meta: {
			pageTitle: "Admin-profile",
		},
		component: () => import('./pages/Admin/Profile.vue')
	},
	{
		path: '/ui-ax/Evaluation',
		name: 'ui-ax-Evaluation',
		meta: {
			pageTitle: "Evaluation",
		},
		component: () => import('./pages/ui-ax/Evaluation/Evaluation.vue')
	},

	{
		path: '/peronnalised-base',
		name: 'peronnalised-base',
		meta: {
			pageTitle: "Base Personnalisée",
		},
		component: () => import('./pages/ui-ax/BasePerso/TextePerso.vue')
	},

	{
		path: '/action-plan',
		name: 'action-plan',
		meta: {
			pageTitle: "Plan d'action",
		},
		component: () => import('./pages/ui-ax/ActionPlan/ActionPlan.vue')
	},
	{
		path: '/rapport',
		name: 'rapport',
		meta: {
			pageTitle: "Rapport",
		},
		component: () => import('./pages/ui-ax/Rapport/Rapport.vue')
	},
	{
		path: '/rapport-text',
		name: 'rapport-text',
		meta: {
			pageTitle: "Textes applicables",
		},
		component: () => import('./pages/ui-ax/Rapport/ListText.vue')
	},
	{
		path: '/rapport-evaluation',
		name: 'rapport-evaluation',
		meta: {
			pageTitle: "Evaluation",
		},
		component: () => import('./pages/ui-ax/Rapport/Evaluation.vue')
	},
	{
		path: '/rapport-compliance-reevaluer',
		name: 'rapport-compliance-reevaluer',
		meta: {
			pageTitle: "Evaluation",
		},
		component: () => import('./pages/ui-ax/Rapport/ExigenceReevaluer.vue')
	},
	{

		path: '/ui-ax/discussions',
		name: 'ui-ax-discussions',
		meta: {
			pageTitle: "Discussions",
		},
		component: () => import('./pages/ui-ax/Hotline/Discussion.vue')
	},
	{
		path: '/ui-ax/chat',
		name: 'ui-ax-chat-discussions',
		meta: {
			pageTitle: "Chat",
		},
		component: () => import('./pages/ui-ax/Hotline/Chat.vue')
	},
	{
		path: '/ui-ax/chat/:id',
		name: 'ui-ax-chat',
		meta: {
			pageTitle: "Chat",
		},
		component: () => import('./pages/ui-ax/Hotline/Chat.vue')
	}, {
		path: '/rapport-action_plan_compliances',
		name: 'rapport-action_plan_compliances',
		meta: {
			pageTitle: "ActionPlanCompliances",
		},
		component: () => import('./pages/ui-ax/Rapport/ActionPlan/ActionPlanCompliance.vue')
	},

	{
		path: '/rapport-action_plan_tasks',
		name: 'rapport-action_plan_tasks',
		meta: {
			pageTitle: "ActionPlanTasks",
		},
		component: () => import('./pages/ui-ax/Rapport/ActionPlan/ActionPlanTask.vue')
	},

	{
		path: '/current_tasks',
		name: 'current_tasks',
		meta: {
			pageTitle: "Tâches en cours",
		},
		component: () => import('./pages/ui-ax/CurrentTasks/CurrentTasks.vue')
	},
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
	scrollBehavior () {
        return { left: 0, top: 0 };
    }
});

router.beforeEach(async (to, from, next) => {
	// current page view title
	document.title = `${to.meta.pageTitle} - ${process.env.VUE_APP_NAME}`;
	// verify auth token before each page change
	await store.dispatch(Actions.VERIFY_AUTH, {api_token: JwtService.getToken(), customHeader: false});
	// before page access check if page requires authentication
	if (to.meta.middleware != "auth") {
		if (store.getters.isUserAuthenticated) {
			if (to.name == "action-plan" && Object.keys(to.query).length > 0) {
				store.commit('setForcePath', true)
			}
			if (!store.getters.getForcePath) {
				const typeSite = siteService.getTypeSite()
				const user = store.getters.currentUser
				const role_user = user.role_user[0]
				const application_master = user.application_master

				if (((to.name == "ui-ax-chat") && (!siteService.getIdSite() || siteService.getIdSite() == 'null' || typeSite != 'SITE')) && !application_master){
					next({name: "dashboard"});
				}

				if ((to.name == "ui-ax-Evaluation" ||  to.name == "peronnalised-base"||  to.name == "action-plan"||  to.name == "current_tasks" ||  to.name == 'rapport-evaluation'||  to.name == 'rapport-text'||  to.name == 'rapport-compliance-reevaluer'||  to.name == 'rapport-action_plan_compliances'||  to.name == 'rapport-action_plan_tasks') && (!siteService.getIdSite() || siteService.getIdSite() == 'null' || typeSite != 'SITE')) {
					if((to.name == "action-plan"||  to.name == 'rapport-evaluation'||  to.name == 'rapport-text'||  to.name == 'rapport-compliance-reevaluer'||  to.name == 'rapport-action_plan_compliances'||  to.name == 'rapport-action_plan_tasks' ||  to.name == "current_tasks") && (typeSite != 'CUSTOMER'))
					{
						next();
					}
					next({name: "dashboard"});
				}
				if (!application_master && role_user != 'ROLE_ADMIN' && (to.name == "admin-text-general" || to.name == "edit-text-general" || to.name == "ui-ax-Admin-customer" || to.name == "ui-ax-Admin-site-master" || to.name == "ui-ax-Admin-user" || to.name == "ui-ax-Admin-site" || to.name == 'rapport-compliance-reevaluer')) {
					next({name: "dashboard"});
				} else if (!application_master && role_user == 'ROLE_ADMIN' && (to.name == "admin-text-general" || to.name == "edit-text-general" || to.name == "ui-ax-Admin-site" || to.name == "ui-ax-Admin-user" || to.name == 'rapport-compliance-reevaluer')) {
					next({name: "dashboard"});
				}
			} else {
				store.commit('setForcePath', false)
			}
			next();
		} else {
			if (to.name == 'action-plan')
				localStorage.setItem('path', <string>to.fullPath);
			next({name: "login"});
		}
	} else if (to.meta.middleware == "auth" && store.getters.isUserAuthenticated) {
		next({name: "dashboard"});
	} else {
		next();
	}
	// Scroll page to top on every route change
	window.scrollTo({
		top: 0,
		left: 0,
		behavior: "smooth",
	});
});

export default router;
